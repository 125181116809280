section.contact-form {
    .panel-image {
        @media screen and (max-width: 576px) {
            & {
                margin-right: 4vw;
            }
        }
    }
    .form-group {
        margin-top: 0px  !important;
        margin-bottom: 10px !important;
    }
    [id*="btnSend"] {
        max-width: 150px;
        padding: 13px 35px;
        font-size: 14px;
        border: 2px solid rgba(71,59,39,.75);
        &:hover {
            background: $primary !important;
            color: $white !important;
            border-color: $primary !important;
        }
    }

    .qc-form-wrapper {
        background: linear-gradient(#34302a 0,#29241c 100%);
        border: 15px solid #fff;
        border-left: none!important;
        padding: 100px 155px;
        margin-bottom: -175px;
    }
    @media screen and (max-width: 1800px) {
        .qc-form-wrapper {
            padding: 70px 130px;
        }
    }
    @media screen and (max-width: 1600px) {
        .qc-form-wrapper {
            margin-bottom: -435px;
            padding: 50px 50px;
        }
    }
    @media screen and (max-width: 1199px) {
        & {
            padding-top: 100px;
        }
        .qc-form-wrapper {
            margin-bottom: -500px;
        }
    }
    @media screen and (max-width: 991px) {
        .qc-form-wrapper {
            margin-bottom: 0;
            padding: 50px 55px;
            margin-top: -80px;
        }
    }
    @media screen and (max-width: 767px) {
        .qc-form-wrapper {
            padding: 50px 25px;
        }
    }
    &.no-services {
        @media screen and (min-width: 992px) {    
            & {
                padding-top: 300px !important;
            }
        }
        
        @media screen and (max-width: 991px) {   
            & {
                padding-top: 0px !important;
            } 
        }
        &.no-testimonials {
            padding-top: 30px !important;
        }
    }

}
