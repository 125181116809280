section.cta-section {
    .top-tag {
        width: 100%;
        left: 0;
        right: 0;
        bottom: 100%;
    }
    .divider-small-border {
        margin-top: 12.5px;
        height: 3px;
        margin-bottom: 12.5px;
        transform: scale(1, 1);
        visibility: visible;
        margin: 25px 0;
        width: 20%;
        height: 1px;
        background-color: #ddd;
    }
    
    .container {
        max-width: 1245px;
        @include media-breakpoint-down(xl) {
            & {
                padding-left: 50px !important;
                padding-right: 50px !important;
            }

        }
        @include media-breakpoint-down(md) {
            & {
                padding-left: 15px !important;
                padding-right: 15px !important;
            }

        }
        @media screen and (max-width: 991px) {
            & {
               max-width: 630px !important;
            }
        }

    }
    .btn {
        font-size: 12px;
        padding: 8px 14px;
        font-weight: 600;
        color: #fff !important;
        border: 2px solid rgba(255,255,255,0.75);
        &:hover {
            border: 2px solid rgba(255,255,255,1);
            color: #fff !important;
        }
    }
    .about-us {
        .panel-image-overlay {
            transition: .3s ease;
            background-color: rgba(49, 41, 27, 0.65);
        }
        &:hover {
            .panel-image-overlay {
                background-color: rgba(49, 41, 27, 0.88);
            }
        }

    }    
    .services {
        .panel-image-overlay {
            transition: .3s ease;
            background-color: rgba(217, 188, 104, 0.88); 
        }
        &:hover {
            .panel-image-overlay {
                background-color: rgba(217, 188, 104, 0.84);
            }
        }

    }
    .contact-us {
        .panel-image-overlay {
            transition: .3s ease;
            background-color: rgba(232, 176, 33, 0.9);
        }
        &:hover {
            .panel-image-overlay {
                background-color: rgba(232, 176, 33, 0.8);
            }
        }

    }
}